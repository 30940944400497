import jwtDecode from "jwt-decode";
import { UserType } from "../types/common";
import adminHttp from "../utils/adminHttp";
import { getToken, setToken } from "../utils/helpers";
import http from "../utils/http";

export const getCurrentUser = () => {
  const token = getToken();
  setToken(token);
  if (token) {
    const response: any = jwtDecode(token);
    return response.data;
  }
};


export const registerDependant = async (credentials: object) => {
  try {
    const response = await http.post("patients/dependent", {
      ...credentials
    });
    return response;
  } catch (error: any) {
    return error.response.data;
  }
};

export const forgotPassword = async ({
  email,
  userType
}: {
  email: string;
  userType: UserType;
}) => {
  try {
    const response = await http.post(`${userType}s/forgot-password`, { email });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    return error.response.data;
  }
};

export const patientUpdateDetails = async (args: object) => {
  try {
    const response = await http.patch("patients/details", args);
    return response;
  } catch (error: any) {
    return error.response.data;
  }
};

export const resendEmail = async (
  userId: string,
  userType: string,
  referrer?: string
) => {
  try {
    const response = await http.get(
      `${userType}/resend-verify-email/${userId}${
        referrer ? "?referrer=appointment" : ""
      }`
    );
    return response;
  } catch (e: any) {
    return e.response.data;
  }
};

// export const adminLogin = async (credentials: object) => {
//   try {
//     const response = await adminHttp.post("admins/login", {
//       ...credentials
//     });
//     if (response.status === 200) {
//       const { token } = response.data;
//       localStorage.setItem("temple_admin_token", token);
//       setAdminToken(token);
//       const currentUser = jwtDecode(token);
//       return {
//         user: currentUser,
//         token
//       };
//     }
//   } catch (error) {
//     return error;
//   }
// };

export const adminSetPassword = async (credentials: object) => {
  try {
    const response = await adminHttp.post("admins/register", {
      ...credentials
    });
    if (response.status === 200) {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

export const getTempAppointmentDetails = async () => {
  try {
    const response = await http.get("patients/temp-appointment");
    if (response.status === 200) {
      return response?.data.tempAppointment;
    }
  } catch (error: any) {
    return error.response.data;
  }
};
