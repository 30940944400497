import { JaaSMeeting } from "@jitsi/react-sdk";
import axios from "axios";
import { add, format } from "date-fns";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useProviderAuthState } from '../../api/hooks/provider/auth/useProviderAuthState';
import { useCancelAppointment } from '../../api/hooks/provider/useCancelAppointment';
import { useGetPatientFormResponse } from '../../api/hooks/provider/useGetPatientResponse';
import { useGetProviderAppointmentCount } from '../../api/hooks/provider/useGetProviderApointmentCount';
import { useGetProviderForm } from '../../api/hooks/provider/useGetProviderForm';
import { useGetProviderProfileDetails } from '../../api/hooks/provider/useGetProviderProfileDetails';
import { useGetPatientUpcomingAppointments } from '../../api/hooks/provider/useGetProviderUpcomingAppointsments';
import { useGetSinglePatientRecord } from "../../api/hooks/provider/useGetSinglePatientRecord";
import { useGetProviderJitsiToken } from '../../api/hooks/provider/useProviderJitsiToken';
import { useGetProviderPatientsCount } from '../../api/hooks/provider/useProviderPatientsCount';
import { useSaveChargeSheetDraft } from '../../api/hooks/provider/useSaveChargeSheet';
import { useSaveFormResponse } from '../../api/hooks/provider/useSaveFormResponse';
import { useSendChargeSheetToBilling } from '../../api/hooks/provider/useSendChargeSheetToBilling';
import { UpcomingAppointmentType } from '../../api/types';
import ReusableTable from "../../components/ReusableTable";
import FormBuilder from "../../components/form/FormBuilder";
import SearchField from "../../components/form/SearchField";
import { VideoModal } from "../../components/introVideoModal";
import ListTabsWidget from "../../components/shared/ListTabsWidget";
import { Loading } from "../../components/shared/Loading";
import { ReactComponent as EmptyMsgIcon } from "../../svgs/empty-smg.svg";
import { Appointment } from "../../types/appointment";
import { NoteValue } from "../pages/PatientDetail/interfaces";
import { SimpleChargeSheetModal } from "./SimpleModal";
import MobileAppointmentCard from "./appointments/MobileAppointmentCard";
import ProviderAppointmentsDrawer from "./appointments/ProviderAppointmentDrawer";
import RescheduleAppointmentModal from "./appointments/RescheduleAppointment";
import AppointmentsCountCard from "./dashboard/AppointmentsCountCard";
import EventCard from "./dashboard/EventCard";
import PatientsCountCard from "./dashboard/PatientsCountCard";
import ProviderDashboardLayout from "./dashboard/ProviderDashboardLayout";
import { useOverViewTableCols } from "./table/hooks/use_overview_cols";
import { getStartTimeOneHourAhead } from "./utils/getStartTimeOneHourAhead";

const ProviderDashboardOverview = () => {
  const { user } = useProviderAuthState()
  const [upcomingAppointmentsNow, setUpcomingAppointmentNow] = useState<UpcomingAppointmentType[]>([]);
  const [appointmentInfo, setAppointmentInfo] = useState<Appointment | null>(null);
  const [openAppointmentDetails, setOpenAppointmentDetails] = useState<boolean>(false);
  const [joinCall, setJoinCall] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeVideoTabLabel, setActiveVideoTabLabel] = useState("Intake Form");
  const [showChargeSheetModal, setShowChargeSheetModal] = useState<boolean>(false);
  const [jitsiToken, setJitsiToken] = useState<string>("");
  const [isRescheduleViewVisible, setIsRescheduleViewVisible] = useState<boolean>(false);
  const [oldAppointmentForReschedule, setOldAppointmentForReschedule] = useState("");
  const [appointmentId, setAppointmentId] = useState<string | null>(null);
  const [isThirtyMinutesBefore, setIsThirtyMinutesBefore] = useState<boolean>(false);
  const [patientId, setPatientId] = useState<string>("");
  const [noteFields, setNoteFields] = useState<NoteValue>({});
  const [isIntroVideo, setIsIntroVideo] = useState<boolean>(true);


  const jaasAppId = process.env.REACT_APP_JAAS_APP_ID || "";

  const handleVideoTabClick = (tabName: string) => {
    setActiveVideoTabLabel(tabName);
  };

  const updateSearch = (event: any) => {
    setSearchTerm(event.target.value.trim().substr(0, 30));
  };

  const { getProviderAppointmentCount, isError: isProvApmntError,
    isLoading: isLoadProvApmnt, error: provApmntError } = useGetProviderAppointmentCount()

  const { providerAppointments, isLoading, isError, error } = useGetPatientUpcomingAppointments()
  const { data: patientData } = useGetSinglePatientRecord(patientId);

  const filteredData =
    providerAppointments?.providersUpcomingAppointments &&
    providerAppointments?.providersUpcomingAppointments?.filter(
      ({ patientName }) => {
        return (
          patientName?.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
        );
      }
    );

  if (isError) {
    const message = axios.isAxiosError(error)
      ? error?.response?.data?.error
      : "Error";
    toast.error(message, { toastId: "customId" });
  }

  if (isProvApmntError) {
    const message = axios.isAxiosError(provApmntError)
      ? provApmntError?.response?.data?.error
      : "Error";
    toast.error(message, { toastId: "customId" });
  }

  const {
    getProviderPatientCount,
    isError: isProvPatientError,
    isLoading: isLoadProvPatient,
    error: provPatientError
  } = useGetProviderPatientsCount();

  if (isProvPatientError) {
    const message = axios.isAxiosError(provPatientError)
      ? provPatientError?.response?.data?.error
      : "Error";
    toast.error(message, { toastId: "customId" });
  }

  useEffect(() => {
    if (!isLoading && providerAppointments?.providersUpcomingAppointments) {
      const appointmentHoldingNow = (
        providerAppointments?.providersUpcomingAppointments || []
      )?.filter(
        (item: any) =>
          new Date().getTime() >= new Date(item?.appointmentDate).getTime()
      );
      setUpcomingAppointmentNow(appointmentHoldingNow);
    }
  }, [isLoading, providerAppointments?.providersUpcomingAppointments]);

  const { getJitsiToken, refetchGetJitsiToken } =
    useGetProviderJitsiToken(joinCall);

  useEffect(() => {
    if (joinCall) {
      refetchGetJitsiToken();
    }
  }, [joinCall, refetchGetJitsiToken]);

  const { saveFormResponse } = useSaveFormResponse();

  const { providerFormData } = useGetProviderForm({
    formType: appointmentInfo?.isInitialAppointment
      ? ["initialVisitNote"]
      : ["progressNote"],
    providerId: user?.userId ? user?.userId : ""
  });

  const { data, isError: isErr, error: err, isLoading: isLoad } = providerFormData[0]

  if (isErr && axios.isAxiosError(err)) {
    toast.error(data?.error?.message, { toastId: "customId" });
  } else if (data?.error !== null) {
    toast.error(data?.error, { toastId: "customId" });
  } else {
    toast.error(
      data?.error?.message || "Error fetching provider's Intake Form",
      { toastId: "customId" }
    );
  }

  const { saveChargeSheet, isPending: isSavingDraft } = useSaveChargeSheetDraft()
  const { postChargeSheetToBilling, isPending: isSendingToBilling } = useSendChargeSheetToBilling()
  const { patientFormResponseData, isLoading: loading, isError: isPError, error: pError } = useGetPatientFormResponse({ appointmentId })

  if (isPError && axios.isAxiosError(pError)) {
    toast.error(pError?.response?.data?.error, { toastId: "customId" });
  }

  const { cancelAppointment } = useCancelAppointment()

  useEffect(() => {
    if (getJitsiToken) {
      setJitsiToken(getJitsiToken);
    }
  }, [getJitsiToken]);

  const checkThirtyMinutesBefore = useCallback(() => {
    const eventStartTime =
      getStartTimeOneHourAhead(upcomingAppointmentsNow) || "00:00:00";
    const currentTime = new Date().toLocaleTimeString("en-US", {
      hour12: false
    });

    const [eventHour, eventMinute] = eventStartTime.split(":").map(Number);
    const [currentHour, currentMinute] = currentTime.split(":").map(Number);

    const eventTimeInMinutes = eventHour * 60 + eventMinute;
    const currentTimeInMinutes = currentHour * 60 + currentMinute;

    const thirtyMinutesBeforeEvent = eventTimeInMinutes - 30;

    setIsThirtyMinutesBefore(
      currentTimeInMinutes >= thirtyMinutesBeforeEvent &&
      currentTimeInMinutes < eventTimeInMinutes
    );
  }, [upcomingAppointmentsNow]);

  useEffect(() => {
    const interval = setInterval(checkThirtyMinutesBefore, 1000); // Check every second
    return () => clearInterval(interval);
  }, [checkThirtyMinutesBefore]);

  if (providerFormData[0].isLoading || isLoad) {
    <div className="fixed inset-0 z-[10000000] backdrop-blur-sm flex justify-center w-full h-screen items-center">
      <Loading />
    </div>;
  }
  const { TABLE_COLUMNS } = useOverViewTableCols();

  const fullPatientData = useMemo(() => {
    return {
      appointmentId: appointmentInfo?.appointmentId,
      patientName: `${patientData?.patientDetails.patient.firstName}${" "}${patientData?.patientDetails.patient.lastName}`,
      providerId: patientData?.patientDetails.patient?.userId,
      dateOfBirth: patientData?.patientDetails.patient.dateOfBirth,
      gender: patientData?.patientDetails.patient.gender,
      patientId: patientData?.patientDetails.patient.userId,
      insurance: patientData?.patientDetails.patient.insuranceName,
      modeOfEncounter: appointmentInfo?.appointmentType as "virtual" | "physical",
      dateOfEncounter: appointmentInfo?.appointmentDate as string,

    }
  }, [patientData, appointmentInfo]);

  const adjustedAppointments =
    providerAppointments?.providersUpcomingAppointments.map(
      ({ appointmentDate, appointmentStartTime, appointmentEndTime, ...rest }) => {
        const composeStartDate = add(new Date(appointmentDate), {
          hours: Number(appointmentStartTime.slice(0, 2)),
          minutes: Number(appointmentStartTime.slice(3, 5))
        });
        const composeEndDate = add(new Date(appointmentDate), {
          hours: Number(appointmentEndTime.slice(0, 2)),
          minutes: Number(appointmentEndTime.slice(3, 5))
        });
        const formatStartDate = format(
          composeStartDate,
          "MM-dd-yy HH:mm"
        );
        return {
          ...rest,
          appointmentDate: formatStartDate.split(" ")[0],
          appointmentStartTime: formatStartDate.split(" ")[1],
          appointmentEndTime: `${composeEndDate.getHours()}:${composeEndDate.getMinutes().toString().padStart(2, "0")}`,
        };
      }
    );

  const { providerData, isLoading: isLoadingProvider } = useGetProviderProfileDetails()

  useEffect(() => {

    if (!isLoadingProvider && providerData?.user?.hideIntroModal) {
      setIsIntroVideo(false)
    }
  }, [providerData, isLoadingProvider])

  const closeVideoModal = () => {
    setIsIntroVideo(false)
  }

  return (
    <>
      {joinCall ? (
        <div className="h-full flex items-center ">
          <JaaSMeeting
            appId={jaasAppId}
            roomName={`${appointmentInfo?.patientId as string}${user?.userId as string}`}
            jwt={jitsiToken}
            // useStaging={true} // dev should remove this prop when the app goes live
            configOverwrite={{
              disableThirdPartyRequests: true,
              disableLocalVideoFlip: true,
              backgroundAlpha: 0.5,
              disableModeratorIndicator: true
              // startWithAudioMuted: true,
              // startScreenSharing: false,
              // enableEmailInStats: false
            }}
            interfaceConfigOverwrite={{
              VIDEO_LAYOUT_FIT: "nocrop",
              MOBILE_APP_PROMO: false,
              TILE_VIEW_MAX_COLUMNS: 4
              // DISABLE_JOIN_LEAVE_NOTIFICATIONS: false
              // SHOW_PROMOTIONAL_CLOSE_PAGE: true
            }}
            userInfo={{
              displayName: `${user?.firstName} ${user?.lastName}`,
              email: user?.email || ""
            }}
            onApiReady={(externalApi: any) => { }}
            onReadyToClose={() => {
              setJoinCall(false);
              setShowChargeSheetModal(true);
            }}
            getIFrameRef={(iframeRef: any) => {
              iframeRef.style.height = "100%";
              iframeRef.style.width = "60%";
            }}
          />
          <div className="w-[40%] h-full px-4 py-5">
            <div className="overflow-y-hidden mb-6 px-4 w-full overflow-x-scroll lg:overflow-x-hidden py-4">
              <ListTabsWidget
                tabs={[{ label: "Intake Form" }, { label: "Notes" }]}
                handleTabClick={(label) => {
                  handleVideoTabClick(label);
                }}
                activeTabLabel={activeVideoTabLabel}
              />
            </div>
            {loading ? (
              <div className="flex justify-center items-center h-1/2"><Loading /></div>
            ) : (
              <>
                {activeVideoTabLabel === "Intake Form" && (
                  <div className="w-full h-full">
                    <div className="w-full flex justify-between items-center mb-6">
                      <p>
                        Intake form answers: {`${appointmentInfo?.patientName}`}
                      </p>
                    </div>
                      {patientFormResponseData?.formResponse?.length ? (
                      <div className="w-full h-full">
                        {patientFormResponseData?.formResponse?.map(
                          (item: any, idx: number) => (
                            <section key={idx} className="text-[18px]">
                              {Object.entries(item).map(([key, value]: any) => (
                                <div className="w-full pb-5" key={key}>
                                  <p className="font-semibold">{key}</p>
                                  {Array.isArray(value) ? (
                                    Object.keys(value[0])?.map(
                                      (item: any, index) => (
                                        <div key={index}>
                                          <input
                                            className="accent-[#3D874E]"
                                            type="checkbox"
                                            checked={value[0][item]}
                                            readOnly={true}
                                          />{" "}
                                          <span>{item}</span>
                                        </div>
                                      )
                                    )
                                  ) : (
                                    <p>{value}</p>
                                  )}
                                </div>
                              ))}
                            </section>
                          )
                        )}
                      </div>
                    ) : (
                          <div className="w-full h-1/2">{patientFormResponseData?.message}</div>
                    )}
                  </div>
                )}

                {activeVideoTabLabel === "Notes" && (
                  <>
                    {data?.initialVisitNote?.noteFields?.length || data?.progressNote?.noteFields?.length > 0 ? (
                      <div className="w-full h-full overflow-y-auto">
                        <div className="w-full flex justify-between items-center mb-12">
                          <p>Progress Note: {`${appointmentInfo?.patientName}`}</p>
                          <p>X</p>
                        </div>
                        <div className="w-full h-full">
                          <FormBuilder
                              formParams={appointmentInfo?.isInitialAppointment ? data?.initialVisitNote?.noteFields ?? [] : data?.progressNote?.noteFields ?? []}
                            formType={
                              appointmentInfo?.isInitialAppointment
                                ? "initialVisitNote"
                                : "progressNote"
                            }
                              submitAction={() => {
                              saveFormResponse({
                                noteType: appointmentInfo?.isInitialAppointment ? "initialVisitNote" : "progressNote",
                                noteFields: [noteFields],
                                appointmentId: appointmentInfo?.appointmentId ?? '',
                                patientId: appointmentInfo?.patientId ?? ''
                              })
                            }}
                            isPreview={false}
                              formAnswers={noteFields}
                              setFormAnswers={setNoteFields}
                          />
                        </div>
                      </div>
                    ) : (
                      <div>No notes!</div>
                    )}
                  </>
                )}

              </>
            )}
          </div>
        </div>
      ) : (
        <ProviderDashboardLayout>
          <div className="w-full h-full px-4 mt-4 lg:mt-16">
            <div className="flex flex-wrap lg:flex-nowrap items-center justify-start my-4">
              <div className="w-full lg:w-[402px]">
                {isLoadProvApmnt ? (<div className="flex justify-center items-center h-1/2">
                    <div className="flex justify-center items-center h-1/2"><Loading /></div>
                </div>) : (
                  <AppointmentsCountCard
                    appointmentCount={getProviderAppointmentCount?.numberOfAppointmentsToday}
                  />
                )}

              </div>
              <div className="w-4 hidden lg:block" />

              <div className="w-full mt-4 lg:mt-0 lg:w-[402px]">
                {isLoadProvPatient ? (<div className="flex justify-center items-center h-1/2">
                    <div className="flex justify-center items-center h-1/2"><Loading /></div>
                </div>) : (
                  <PatientsCountCard patientsCount={getProviderPatientCount?.numberOfPatients} />
                )}
              </div>
              <div className="w-4 hidden lg:block" />

              {isThirtyMinutesBefore && (
                <div className="w-full lg:w-[402px]">
                  {upcomingAppointmentsNow &&
                      upcomingAppointmentsNow?.length ? (
                    upcomingAppointmentsNow?.map((eventItem: any) => (
                      <Fragment key={eventItem?.id}>
                        <EventCard
                          name={eventItem?.patientName}
                          diagnosis={eventItem?.diagnosis ?? "No Diagnosis"}
                          startTime={eventItem?.appointmentStartTime}
                          endTime={eventItem?.appointmentEndTime}
                          onClick={() => {
                            if (eventItem?.appointmentId) {
                              setAppointmentId(eventItem?.appointmentId)
                              setPatientId(eventItem?.patientId)
                            }
                            setAppointmentInfo(eventItem);
                            // refetchPatientFormResponse()
                            setOpenAppointmentDetails(true);
                            providerFormData[0].refetch();
                          }}
                        />
                      </Fragment>
                    ))
                  ) : (
                    <p className="hidden lg:block w-full text-center text-[20px] text-[#324705] font-[500]">
                      No Events
                    </p>
                  )}
                </div>
              )}
            </div>
            <div className="w-full">
              <p className="text-sm lg:text-[28px] text-[#324705] font-[600] py-4 ml-4">
                Upcoming Appointments
              </p>
              {providerAppointments?.providersUpcomingAppointments?.length ? (
                <>
                  <div className="hidden lg:block w-full my-4">
                    {isLoadProvApmnt ? (<div className="flex justify-center items-center h-1/2">
                      <Loading />
                    </div>) : (
                      <ReusableTable
                        tableColumns={TABLE_COLUMNS}
                        tableData={adjustedAppointments as UpcomingAppointmentType[]}
                        onClick={data => {
                          if (data?.appointmentId) {
                            setAppointmentId(data?.appointmentId);
                            setPatientId(data?.patientId);
                          }
                          setAppointmentInfo(data);
                          setOpenAppointmentDetails(true);
                          providerFormData[0].refetch();
                        }}
                        searchFieldPlaceholder="Search Patient"
                      />
                    )}
                  </div>
                  <div className="w-fullblock lg:hidden  pb-2">
                    <SearchField
                      placeholder="Search appointment"
                      value={searchTerm}
                      onChange={updateSearch}
                      name="searchTerm"
                    />
                  </div>
                  <div className="w-full block lg:hidden lg:mt-8 shadow-[-1.74129px_3.48259px_21.7662px_#EEEEE9]">
                    <p className="w-full h-[53px] bg-[#EEEEE9] text-[#34623F] text-[500] flex items-center my-2 px-4">
                      Patients
                    </p>
                    {filteredData &&
                      filteredData?.map((item: any, index: number) => (
                        <MobileAppointmentCard
                          key={index}
                          appointmentDetails={item && item}
                        />
                      ))}
                  </div>
                </>
              ) : (
                <>
                  <div className="h-full w-full m-auto mt-[40px] flex flex-col justify-center items-center">
                    <EmptyMsgIcon />
                    <div className="font-semibold text-[#34623f] mt-4 text-center">
                      Nothing here
                    </div>
                    <div className="text-[#103C1B] pt-2 lg:w-[322px] text-center">
                      You don’t have any upcoming appointments
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <ProviderAppointmentsDrawer
            setIsRescheduleViewVisible={setIsRescheduleViewVisible}
            setOldAppointmentForReschedule={setOldAppointmentForReschedule}
            open={openAppointmentDetails}
              onClose={() => {
                setOpenAppointmentDetails(false);
            }}
            drawerTitle="Appointment Details"
            appointmentDetails={appointmentInfo && appointmentInfo}
              clickJoinCall={() => {
                setJoinCall(true)
                setOpenAppointmentDetails(false);
            }}
            patientIntakeFormResponse={
              patientFormResponseData?.formResponse
            }
            handleCancelAppointment={() => {
              cancelAppointment({
                appointmentId: appointmentInfo?.appointmentId
              });
              setOpenAppointmentDetails(false);
              setAppointmentInfo(null);
            }}
          />
          <RescheduleAppointmentModal
            isVisible={isRescheduleViewVisible}
              closeModal={() => {
                setIsRescheduleViewVisible(false);
            }
            }
            oldId={oldAppointmentForReschedule}
          />
        </ProviderDashboardLayout>
      )}
      {
        showChargeSheetModal ? (
          <div className="w-full h-[90%] lg:max-w-[90%] max-w-[95%] flex items-center justify-center border-box">
            <div className="w-full h-[90%] lg:max-w-[90%] max-w-[95%] my-8 mx-4 overflow-y-scroll rounded-tl-[16px]">
              <SimpleChargeSheetModal
                open={showChargeSheetModal}
                setOpen={setShowChargeSheetModal}
                chargeSheetData={fullPatientData}
                isSendingToBilling={isSendingToBilling}
                isSavingDraft={isSavingDraft}
                isFromNewBilling={false}
                saveAction={({ patientId, appointmentId, procedureCodes, diagnosisCodes, modeOfEncounter, locationOfEncounter,
                  dateOfEncounter }) => {
                  saveChargeSheet({
                    patientId,
                    appointmentId,
                    procedureCodes,
                    diagnosisCodes,
                    status: "draft",
                    modeOfEncounter,
                    locationOfEncounter,
                    dateOfEncounter
                  });
                  setShowChargeSheetModal(false);
                }}

                sendToBillingAction={({ patientId, appointmentId, procedureCodes, diagnosisCodes, modeOfEncounter, locationOfEncounter, dateOfEncounter }) => {
                  postChargeSheetToBilling({
                    patientId,
                    appointmentId,
                    procedureCodes,
                    diagnosisCodes,
                    status: "processing",
                    modeOfEncounter,
                    locationOfEncounter,
                    dateOfEncounter
                  });
                  setShowChargeSheetModal(false);
                }}
              />
            </div>
          </div>
        ) : null
      }
      {
        isIntroVideo ? (
          <VideoModal
            isOpen={isIntroVideo}
            handleClose={closeVideoModal}
          />
        ) : null
      }
    </>
  );
};

export default ProviderDashboardOverview;
